import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Typography, TextField, Button, AppBar, Toolbar, CircularProgress } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import AppointmentForm from './AppointmentForm';

const temp_appointments = [
    {
        "date": "02/10/24",
        "patient": "Test",
        "roomid": "test234"
    },
    {
        "date": "02/11/24",
        "patient": "Tayler Mauldin",
        "roomid": "test456"
    }
]

const appointmentItemStyles = {
    border: 1,
    borderRadius: '8px',
    boxShadow: '2px 2px 4px #aaa',
    p: 2,
    mb: 2,
    bgcolor: 'background.paper',
};

const DoctorProfile = () => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [requestApptOpen, setRequestApptOpen] = useState(false);
    const [appointments, setAppointments] = useState(temp_appointments);
    const navigate = useNavigate();

    const handleRequestApptOpen = () => {
        setRequestApptOpen(true);
    };

    const handleRequestApptClose = () => {
        setRequestApptOpen(false);
    };

    const handleAddAppointment = (newAppointment) => {
        console.log("handleAddAppointment...")
        const appointmentToAdd = {
            date: newAppointment.date, // Ensure format matches, e.g., "MM/DD/YY"
            doctor: newAppointment.doctor,
            roomid: newAppointment.roomid || "defaultRoomId" // Provide a default or derive from newAppointment
        };
        setAppointments((currentAppointments) => [...currentAppointments, appointmentToAdd]);
    };

    const handleJoinAppointment = async (appointment) => {
        console.log(`Join the appointment... ${appointment}`)
        const roomId = appointment.roomid;
        console.log(`Testing the roomId ${roomId}`)
        try {
            navigate(`/video-session/${roomId}`)
        } catch (error) {
            console.log("Error", error)
        }
        // updateUserRoom(roomId)
        // navigate(`/video-session/${roomId}`)
    }

    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');

            if (!token) {
                // TODO: Create some good visual for this
                console.error('No token found');
                setLoading(false);
                return;
            }

            try {
                // Decode the JWT token to get the user's ID
                const decodedToken = jwtDecode(token);
                const userId = decodedToken.userId;

                const response = await fetch(`https://scope-api.trl-ai.com/users/${userId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }

                const data = await response.json();
                setUserData(data);
            } catch (error) {
                console.error('Error:', error);
                // Handle errors appropriately
            } finally {
                setLoading(false);
            }

        };

        fetchUserData();
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    if (!userData) {
        return <Typography>No user data found</Typography>;
    }

    return (
        <Grid container spacing={4} padding={2}>
            <Grid item md={3}></Grid>
            <Grid item md={3}>
                <Typography>Doctor Name: {userData.name}</Typography>
            </Grid>
            <Grid item md={3}>
                {appointments && appointments.map((appointment, index) => (
                    <Grid item key={index} md={12} sx={appointmentItemStyles}>
                        <Typography>Appointment Date: {appointment.date}</Typography>
                        <Typography>Patient: {appointment.patient}</Typography>
                        <Button onClick={() => handleJoinAppointment(appointment)}>Join</Button>
                    </Grid>
                ))}
                <Button color="inherit" onClick={handleRequestApptOpen}>Request Appointment</Button>
            </Grid>
            <Grid item md={3}></Grid>

            {/* AppointmentForm modal */}
            <AppointmentForm open={requestApptOpen} handleClose={handleRequestApptClose} onAddAppointment={handleAddAppointment} />
        </Grid>
    );
}    

export default DoctorProfile;