import React, { useState } from 'react';
import { Modal, Box, Typography, List, ListItem, ListItemText, Button, Select, MenuItem } from '@mui/material';

const appointments = [
    {
        "doctor": "Dr. Smith",
        "date": "2024-03-15",
        "time": "10:00 AM",
        "roomid": "test789"
    },
    {
        "doctor": "Dr. Smith",
        "date": "2024-03-16",
        "time": "02:00 PM",
        "roomid": "test1011"
    },
    {
        "doctor": "Dr. Jones",
        "date": "2024-03-17",
        "time": "11:00 AM",
        "roomid": "test1213"
    },
    {
        "doctor": "Dr. Jones",
        "date": "2024-03-18",
        "time": "01:00 PM",
        "roomid": "test1415"
    },
    {
        "doctor": "Dr. Lee",
        "date": "2024-03-19",
        "time": "09:00 AM",
        "roomid": "test1617"
    },
    {
        "doctor": "Dr. Lee",
        "date": "2024-03-20",
        "time": "03:00 PM",
        "roomid": "test1819"
    }
];

function AppointmentForm({ open, handleClose, onAddAppointment }) {
    const [selectedDoctor, setSelectedDoctor] = useState('');
    const [filteredAppointments, setFilteredAppointments] = useState(appointments);

    const handleDoctorChange = (event) => {
        setSelectedDoctor(event.target.value);
        if (event.target.value === '') {
            setFilteredAppointments(appointments);
        } else {
            setFilteredAppointments(appointments.filter(appointment => appointment.doctor === event.target.value));
        }
    };

    return (
        <div>
            <Modal open={open} onClose={handleClose}>
                <Box p={2} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', boxShadow: 24 }}>
                    <Typography variant="h6" mb={2}>Appointments</Typography>
                    <Select value={selectedDoctor} onChange={handleDoctorChange} displayEmpty>
                        <MenuItem value="">All Doctors</MenuItem>
                        {/* List unique doctors as options */}
                        {[...new Set(appointments.map(appointment => appointment.doctor))].map((doctor, index) => (
                            <MenuItem key={index} value={doctor}>{doctor}</MenuItem>
                        ))}
                    </Select>
                    <List>
                        {filteredAppointments.map((appointment, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={`${appointment.doctor} - ${appointment.date} at ${appointment.time}`} secondary={appointment.complaint} />
                                <Button onClick={() => onAddAppointment(appointment)}>Select</Button>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Modal>
        </div>
    );
}

export default AppointmentForm;